<template>
  <div class="main-panel">
    <div class="w-100 h-100 d-flex justify-content-center align-items-center">
      <spinner v-if="isLoading"></spinner>
    </div>
  </div>
</template>

<script>
import usePusher from '@/use/pusher'
import Spinner from './Spinner.vue'

export default {
  components: { Spinner },
  name: 'check-agent',

  setup() {
    const { subscribeChatChannel } = usePusher()

    return {
      subscribeChatChannel,
    }
  },

  data() {
    return {
      isLoading: true,
    }
  },

  mounted() {
    window.parent.postMessage(
      {
        widgetTitle: 'ติดต่อเจ้าหน้าที่',
      },
      '*'
    )
  },
}
</script>

<style scoped>
.main-panel {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100vh;
}
</style>
